// スマホだと2回タップしないとリンク先に飛ばない問題の対策
window.ontouchstart = function() {};
window.addEventListener('touchstart', function() {}, true);
window.addEventListener('touchstart', function() {}, false);

// 年数（コピーライト）
$(function(){
  var year = new Date().getFullYear();
  $("#year").html(year);
});

//Menu
var state = false;
var pos;
$("#menu-btn").on("click",function(){
  if (state == false) {
    pos = $(window).scrollTop();
    $('body').addClass('fixed').css({'top': -pos});
    state = true;
  } else {
    $('body').removeClass('fixed').css({'top': 0});
    window.scrollTo(0, pos);
    state = false;
  }

  $("#menu").toggleClass("open");
});

// header
$(window).scroll(function () {
  if($(window).scrollTop() > 60) {
    $('.header-btns').addClass('scroll');
  } else {
    $('.header-btns').removeClass('scroll');
  }
});